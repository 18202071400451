import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/PageHome"),
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import("@/views/PageMenuBack"),
  },
  {
    path: "/menu-with-back",
    name: "menu-with-back",
    component: () => import("@/views/PageMenu"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/job",
    name: "vacancies",
    component: () => import("@/views/PageVacancies"),
  },
  {
    path: "/dog-help",
    name: "dogHelp",
    component: () => import("@/views/PageDogHelp"),
  },
  {
    path: "/contacts",
    name: "contacts",
    component: () => import("@/views/PageContacts"),
  },
  {
    path: "/whales",
    name: "whales",
    component: () => import("@/views/PageWhales"),
  },
  {
    path: "/admin-login",
    name: "adminLogin",
    component: () => import("@/views/PageLogin"),
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next({ name: "adminLogin", query: { from: to.fullPath } });
  } else {
    next();
  }
});

export default router;
