import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__logo d-flex gap-3" }
const _hoisted_3 = {
  key: 0,
  class: "header__links"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_mobile_menu = _resolveComponent("mobile-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "header__logo-link",
        to: { name: 'home' }
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Brewsell ")
        ]),
        _: 1
      }),
      (_ctx.isAdmin)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "header__logo-link-admin",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
          }, " Admin "))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            class: "header__link",
            to: { name: 'menu' }
          }, {
            default: _withCtx(() => [
              _createTextVNode("Меню")
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            class: "header__link",
            to: { name: 'vacancies' }
          }, {
            default: _withCtx(() => [
              _createTextVNode("Вакансии")
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            class: "header__link",
            to: { name: 'contacts' }
          }, {
            default: _withCtx(() => [
              _createTextVNode("Контакты")
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showMobileMenu = true))
        }, [
          _createElementVNode("img", {
            src: require('@/assets/List.svg')
          }, null, 8, _hoisted_4)
        ])),
    _createVNode(_component_mobile_menu, {
      "show-menu": _ctx.showMobileMenu,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showMobileMenu = false))
    }, null, 8, ["show-menu"])
  ]))
}