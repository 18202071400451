<template>
  <div class="footer">
    <div class="footer__left-right-wrapper">
      <div class="footer__left">
        <div class="footer__left-links">
          <div class="footer__left-column">
            <span class="footer__left-column-title">Главная</span>
            <router-link class="footer__left-column-link" :to="{ name: 'menu' }"
              >Меню</router-link
            >
            <span class="footer__left-column-disabled">Магазин</span>
            <router-link class="footer__left-column-link" :to="{ name: 'home' }"
              >Вакансии</router-link
            >
            <router-link
              class="footer__left-column-link"
              :to="{ name: 'contacts' }"
              >Контакты</router-link
            >
            <span class="footer__left-column-disabled">О нас</span>
          </div>
          <div class="footer__left-column">
            <span class="footer__left-column-title">Интересные штучки</span>
            <router-link
              class="footer__left-column-link"
              :to="{ name: 'dogHelp' }"
              >Песики</router-link
            >
            <router-link
              class="footer__left-column-link"
              :to="{ name: 'whales' }"
              >Как собирать китов</router-link
            >
            <span class="footer__left-column-disabled"
              >Подарочные сертификаты</span
            >
          </div>
          <div class="footer__left-column">
            <span class="footer__left-column-title">Магазин</span>
            <span class="footer__left-column-disabled">Зерно</span>
            <span class="footer__left-column-disabled">Мерч</span>
            <span class="footer__left-column-disabled">Дриппы</span>
          </div>
          <div class="footer__left-column">
            <span class="footer__left-column-title">Наши продукты</span>
            <span class="footer__left-column-disabled">Наши кофейни</span>
            <span class="footer__left-column-disabled">BrewNinja</span>
          </div>
        </div>
        <div class="footer__left-right-divider"></div>
        <div class="footer__left-BCC">
          <div class="footer__small-logo">Brewsell</div>
          © {{ new Date().getFullYear() }} Brewsell coffee corporation
        </div>
      </div>
      <div class="footer__right">
        <base-title title-text="Обратная связь" font-size="20px" />
        <base-description
          class="footer__right-description"
          description-text="Мы будем рады услышать вашу обратную связь, и обязательно что-то с этим сделаем."
          text-align="left"
        />
        <a class="footer__right-link" href="mailto:brewsell@brewsell.com"
          >brewsell@brewsell.com</a
        >
        <div class="footer__left-right-divider"></div>
        <div class="footer__right-icons-wrapper">
          <a
            href="https://www.instagram.com/brewsell?igsh=a3V0YTloYTMxNW1z"
            target="_blank"
          >
            <img :src="require('@/assets/InstagramLogo.svg')" />
          </a>
          <a href="https://t.me/brewsell_coffee" target="_blank">
            <img :src="require('@/assets/TelegramLogo.svg')" />
          </a>
        </div>
      </div>
    </div>
    <div class="footer__logo">
      <img :src="require('@/assets/BrewsellLogo.png')" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import BaseTitle from "@/components/BaseTitle/BaseTitle.vue";
import BaseDescription from "@/components/BaseDescription/BaseDescription.vue";

export default defineComponent({
  name: "BaseFooter",
  components: { BaseDescription, BaseTitle },
  setup() {
    const footerLinks = [{ title: "Главное" }];
  },
});
</script>
<style lang="scss">
.footer {
  &__left-right-wrapper {
    display: flex;
    gap: 30px;
  }
  &__left {
    width: 100%;
    padding: 24px 24px 32px 24px;
    border-radius: 10px;
    border: 2px solid #e2e8f0;
  }
  &__left-links {
    display: flex;
    justify-content: space-between;
  }
  &__left-column {
    gap: 12px;
    display: flex;
    flex-direction: column;
  }
  &__left-column-title {
    color: #475569;
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  &__left-column-link {
    text-decoration: none;
    color: #0f172a;
    font-family: Inter, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  &__left-column-disabled {
    color: #94a3b8;
    font-family: Inter, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  &__left-BCC {
    text-align: center;
    color: #0f172a;
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  &__right {
    width: 43%;
    border-radius: 10px;
    border: 2px solid #e2e8f0;
    padding: 24px 32px;
  }
  &__right-description {
    margin: 16px 0;
  }
  &__right-link {
    color: #0671e0;
    text-decoration: none;
    font-family: Inter, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  &__left-right-divider {
    height: 1px;
    background: #e2e8f0;
    margin: 16px 0;
  }
  &__right-icons-wrapper {
    display: flex;
    justify-content: center;
    gap: 24px;
  }
  &__small-logo {
    display: none;
  }
  &__logo {
    margin-top: 100px;
    img {
      width: 100%;
    }
  }
}
@media (max-width: 768px) {
  .footer {
    &__left-right-wrapper {
      display: flex;
      flex-direction: column-reverse;
    }
    &__right {
      width: auto;
    }
    &__left {
      width: auto;
    }
    &__left-links {
      flex-wrap: wrap;
      gap: 30px;
    }
    &__left-column {
      gap: 6px;
      width: 40%;
    }
    &__left-column-title {
      font-size: 12px;
    }
    &__left-column-link {
      font-size: 14px;
    }
    &__left-column-disabled {
      font-size: 14px;
    }
    &__logo {
      display: none;
    }
    &__small-logo {
      margin-bottom: 12px;
      font-family: Inter, serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 116.667% */
      text-transform: uppercase;
      display: block;
    }
  }
}
</style>
